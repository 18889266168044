import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import moment from "moment-timezone";
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import GoogleMapComponent from "../../components/map/GoogleMapComponent";
import { db } from "../../firebase";
import { database } from "../../firebase";
import { ref, onValue, set, serverTimestamp } from 'firebase/database';
import {
  collection,
  query,
  where,
  onSnapshot,
  Timestamp,
  getDocs,
  addDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";
import EMS from "../../components/images/EMS.png";
import FIRE from "../../components/images/FIRE.png";
import HP from "../../components/images/HP.png";
import hydrantdata from "../../Assets/suffolkhydrants.json";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import notificationSound from "../../sounds/PSAPTone.wav";
import notificationMp3 from "../../Assets/notification.mp3";
import { FaAmbulance, FaFireAlt, FaSyncAlt, FaTimes } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
const Home = () => {
  const { userData } = useContext(AuthContext);
  const location = useLocation();
  const toastId = useRef(null);
  const processedDocIds = useRef(new Set());
  const [alarmData, setAlarmData] = useState([]);
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [prevFresComments, setPrevFresComments] = useState(null);
  const [mapLocation, setMapLocation] = useState(null);
  const [lastUpdatedText, setLastUpdatedText] = useState("");
  const [callins, setCallins] = useState([]);
  const [hydrantLocations, setHydrantLocations] = useState([]);
  const [vehicleUnits, setVehicleUnits] = useState([]);
  const [callerLocation, setCallerLocation] = useState(null);
  const [isCloseAlarmModalOpen, setIsCloseAlarmModalOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const notificationAudio = useRef(new Audio(notificationMp3));

  useEffect(() => {
    setHydrantLocations(hydrantdata);
  }, []);

  const extractCallerCoordinates = useCallback((eventComments) => {
    console.log('Extracting caller coordinates from Event Comments');
    
    // Define a regular expression pattern to match coordinates
    const regex = /-?\d+\.\d+\s+-?\d+\.\d+/;
    
    if (!eventComments) {
      console.log('No Event Comments available.');
      return;
    }

    // Find matches in the EventComments text
    const matches = eventComments.match(regex);
    
    if (matches && matches.length > 0) {
      // The first match contains the coordinates
      const coordinates = matches[0];
      console.log('Extracted Coordinates:', coordinates);
    
      // Parse the coordinates
      const [longitudeStr, latitudeStr] = coordinates.split(' ');
      const latitude = parseFloat(latitudeStr);
      const longitude = parseFloat(longitudeStr);
      
      console.log('Parsed Latitude:', latitude);
      console.log('Parsed Longitude:', longitude);
      console.log('Setting callerLocation state...');
    
      if (!isNaN(latitude) && !isNaN(longitude)) {
        // Save the coordinates in state
        setCallerLocation({ latitude, longitude });
      } else {
        console.log('Invalid coordinates extracted.');
      }
    } else {
      console.log('No coordinates found in Event Comments.');
    }
  }, []);

  const fetchVehicleUnits = useCallback(() => {
    if (userData?.agency) {
      const unitsRef = ref(database, `/UnitLocation/${userData.agency}`);
  
      const unsubscribe = onValue(unitsRef, (snapshot) => {
        const unitsData = snapshot.val();
        if (unitsData) {
          const unitArray = Object.keys(unitsData).map(key => ({
            responderName: key,
            ...unitsData[key],
          }));
          setVehicleUnits(unitArray); // Set the fetched units to state
        }
      });
  
      return () => unsubscribe(); // Clean up the subscription
    }
  }, [userData]);
  

  useEffect(() => {
    fetchVehicleUnits(); // Fetch vehicle units when the component mounts
  }, [fetchVehicleUnits]);

  const getVehicleIcon = (vehicleType) => {
    switch ((vehicleType || "").toLowerCase()) {
      case "engine":
        return FIRE;
      case "chief":
        return HP;
      case "ambulance":
        return EMS;
      default:
        return FIRE;
    }
  };


  const geocodeAddress = async (address, town, docData) => {
    // Check if latitude and longitude are available in the document data
    if (docData?.Latitude && docData?.Longitude) {
      return {
        lat: parseFloat(docData.Latitude),
        lng: parseFloat(docData.Longitude)
      };
    }

    const apiKey = "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM";
    const fullAddress = `${address}, ${town}`;
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address: fullAddress,
          key: apiKey,
        },
      }
    );

    if (response.data.status === "OK") {
      const location = response.data.results[0].geometry.location;
      return location;
    } else {
      console.error("Geocoding error:", response.data.status);
      return null;
    }
  };

  const fetchCallinsAndQualifications = async (alarm, agency) => {
    const callinsRef = collection(db, `${agency} Alarms/${alarm.id}/Callins`);
    const callinsSnapshot = await getDocs(callinsRef);
    const callins = await Promise.all(
      callinsSnapshot.docs.map(async (callinDoc) => {
        const callinData = callinDoc.data();
        const qualificationsRef = collection(
          db,
          "Agencies",
          agency,
          "Qualifications"
        );
        const qualificationsSnapshot = await getDocs(qualificationsRef);
        const userQualifications = [];

        qualificationsSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.users && data.users.includes(callinData.id)) {
            userQualifications.push(doc.id);
          }
        });

        console.log(
          `Callin User: ${
            callinData.name
          }, Qualifications: ${userQualifications.join(", ")}`
        );

        return {
          ...callinData,
          qualifications: userQualifications,
        };
      })
    );
    return callins;
  };

  const handleNewDocument = async (change) => {
    const data = change.doc.data();
    const location = await geocodeAddress(data.Address, data.Town, data);

    if (
      change.type === "added" &&
      !processedDocIds.current.has(change.doc.id)
    ) {
      const audio = new Audio(notificationSound);
       audio.play();

      const address = data.Address;
      const calltype = data.CallType;

      const toastId = toast(
        `A new call has been added to your queue! ${calltype} At Address: ${address}`,
        {
          position: "top-center",
          autoClose: false,
          closeButton: (
            <button
              style={{
                backgroundColor: "green",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={() => {
                toast.dismiss(toastId);
                audio.pause();
                 audio.currentTime = 0;
              }}
            >
              Acknowledge
            </button>
          ),
          closeOnClick: false,
        }
      );

      processedDocIds.current.add(change.doc.id);
    }

    return { ...data, location };
  };

  const fetchAlarms = useCallback(() => {
    if (userData?.agency) {
      const currentTime = new Date();
      const pastTime = new Date(currentTime.getTime() - 1.5 * 60 * 60 * 1000);
      console.log("Fetching alarms from:", pastTime);

      const q = query(
        collection(db, `${userData.agency} Alarms`),
        where("TimeStamp", ">=", Timestamp.fromDate(pastTime))
      );

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        console.log("Query snapshot size:", querySnapshot.size);
        const alarms = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            if (!data.RunNumber) return null; // Filter out documents that don't have "RunNumber"
            const location = await geocodeAddress(data.Address, data.Town, data);
            const callins = await fetchCallinsAndQualifications(
              { ...data, id: doc.id },
              userData.agency
            );

            // Use Eventtime if TimeStamp is null
            const timeStamp = data.TimeStamp
              ? data.TimeStamp.toDate()
              : moment(
                  data.Eventtime.split("Dispatch Time: ")[1],
                  "MM-DD-YYYY HH:mm:ss"
                ).toDate();

            return { ...data, location, callins, id: doc.id, timeStamp };
          })
        );

        const nonNullAlarms = alarms.filter((alarm) => 
          alarm !== null && alarm.AlarmStatus !== "false"
        );
        nonNullAlarms.sort((a, b) => b.timeStamp - a.timeStamp);

        setAlarmData(nonNullAlarms);
        console.log("Fetched alarms with locations:", nonNullAlarms);
      });

      return () => unsubscribe();
    }
  }, [userData]);

  const setupNotificationListener = useCallback(() => {
    if (userData?.agency) {
      const currentTime = new Date();
      const pastTime = new Date(currentTime.getTime() - 1.5 * 60 * 60 * 1000);
      console.log("Setting up notification listener from:", pastTime);

      const q = query(
        collection(db, `${userData.agency} Alarms`),
        where("TimeStamp", ">=", Timestamp.fromDate(pastTime))
      );

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const newAlarms = await Promise.all(
          querySnapshot.docChanges().map(async (change) => {
            console.log("CHANGES 2");
            const data = change.doc.data();
            if (!data.RunNumber) return null; // Filter out documents that don't have "RunNumber"
            return handleNewDocument(change);
          })
        );
        console.log("Processed new alarms for notification:", newAlarms);
      });

      return () => unsubscribe();
    }
  }, [userData]);

  const setupCallinsListener = (alarm, agency) => {
    const callinsRef = collection(db, `${agency} Alarms/${alarm.id}/Callins`);
    const unsubscribe = onSnapshot(callinsRef, async (snapshot) => {
      const callinsData = await Promise.all(
        snapshot.docs.map(async (callinDoc) => {
          const callinData = callinDoc.data();
          const qualificationsRef = collection(
            db,
            "Agencies",
            agency,
            "Qualifications"
          );
          const qualificationsSnapshot = await getDocs(qualificationsRef);
          const userQualifications = [];

          qualificationsSnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.users && data.users.includes(callinData.id)) {
              userQualifications.push(doc.id);
            }
          });

          return {
            ...callinData,
            qualifications: userQualifications,
          };
        })
      );
      console.log("SETTING HERE NOW");
      // setSelectedAlarm((prevAlarm) => ({
      //   ...prevAlarm,
      //   Callins: callinsData,
      // }));
      setCallins(callinsData);
    });

    return () => unsubscribe();
  };

  const handleCloseAlarm = async () => {
    if (selectedAlarm && userData?.agency) {
      try {
        const alarmRef = doc(db, `${userData.agency} Alarms`, selectedAlarm.id);
        await updateDoc(alarmRef, {
          AlarmStatus: "false"
        });
        toast.success("Alarm closed successfully");
        setIsCloseAlarmModalOpen(false);
      } catch (error) {
        console.error("Error closing alarm:", error);
        toast.error("Failed to close alarm");
      }
    }
  };

  const handleToggleMute = (muted) => {
    setIsMuted(muted);
  };

  useEffect(() => {
    if (selectedAlarm?.FRESComments && prevFresComments !== selectedAlarm.FRESComments && !isMuted) {
      notificationAudio.current.play();
      setPrevFresComments(selectedAlarm.FRESComments);
    }
  }, [selectedAlarm?.FRESComments, prevFresComments, isMuted]);

  useEffect(() => {
    const unsubscribeFetchAlarms = fetchAlarms();
    const unsubscribeNotification = setupNotificationListener();
    return () => {
      if (unsubscribeFetchAlarms) unsubscribeFetchAlarms();
      if (unsubscribeNotification) unsubscribeNotification();
    };
  }, [fetchAlarms, setupNotificationListener]);

  useEffect(() => {
    if (selectedAlarm && userData?.agency) {
      const updateTime = selectedAlarm.TimeStamp.toDate();
      const estTimeUpdate = moment(updateTime).tz("America/New_York");
      const lastUpdateTime = estTimeUpdate.format();
      setLastUpdatedText(`Last updated ${moment(lastUpdateTime).fromNow()}`);
      const unsubscribeCallins = setupCallinsListener(
        selectedAlarm,
        userData.agency
      );
      return () => {
        if (unsubscribeCallins) unsubscribeCallins();
      };
    }
  }, [selectedAlarm, userData?.agency]);

  const handleRowClick = async (alarm) => {
    const callins = await fetchCallinsAndQualifications(alarm, userData.agency);
    setSelectedAlarm({ ...alarm });
    setCallins(callins);
    if (alarm.location) {
      setMapLocation(alarm.location);
    }
    // Extract caller coordinates when an alarm is selected
    if (alarm?.EventComments) {
      extractCallerCoordinates(alarm.EventComments);
    }

    // Update selected alarm in Firebase for FullScreenMap
    if (userData?.agency) {
      const selectedAlarmRef = ref(database, `/SelectedAlarm/${userData.agency}`);
      await set(selectedAlarmRef, {
        alarmId: alarm.id,
        timestamp: serverTimestamp()
      });
    }
  };

  const getCallCardImage = (callcard) => {
    switch (callcard) {
      case "EMS":
        return EMS;
      case "FIRE":
        return FIRE;
      case "**HIGH PRIORITY**":
        return HP;
      default:
        return null;
    }
  };

  const handleDispatchResponse = async (responseType) => {
    if (selectedAlarm) {
      if (
        window.confirm(
          `Are you sure you want to send "${responseType}" message to Suffolk County Fire Rescue?`
        )
      ) {
        const remarksRef = collection(
          db,
          `${userData.agency} Alarms/${selectedAlarm.id}/Remarks`
        );
        await addDoc(remarksRef, {
          responseType,
          RunNumber: selectedAlarm.RunNumber ?? "",
          CadSequence: selectedAlarm.CadSequence ?? "",
          CommonID: selectedAlarm.CommonID ?? "",
          AgencyIdentifier: selectedAlarm.Agency ?? "",
          tag: userData.tag ?? "",
          VenderID: "Laddersuite",
          timestamp: serverTimestamp(),
          ISOstamp: new Date().toISOString(),
        });
        alert(`${responseType} response sent successfully.`);
      }
    } else {
      alert("No alarm selected.");
    }
  };

  const handleRefresh = async () => {
    if (selectedAlarm && userData?.agency) {
      const alarmDocRef = doc(
        db,
        `${userData.agency} Alarms`,
        selectedAlarm.id
      );
      const alarmDoc = await getDoc(alarmDocRef);
      if (alarmDoc.exists()) {
        const data = alarmDoc.data();
        const callins = await fetchCallinsAndQualifications(
          { ...data, id: alarmDoc.id },
          userData.agency
        );
        console.log("REFRESHING");
        setSelectedAlarm({
          ...data,
          location: selectedAlarm.location,
          id: alarmDoc.id,
        });
        setCallins(callins);
      } else {
        alert("Failed to fetch the updated call data.");
      }
    } else {
      alert("No alarm selected or agency data unavailable.");
    }
  };

  useEffect(() => {
    if (userData?.agency) {
      const q = query(collection(db, `${userData.agency} Alarms`));
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const changes = snapshot.docChanges();
        console.log("CHANGES");
        for (let change of changes) {
          if (
            change.type === "modified" &&
            selectedAlarm &&
            change.doc.id === selectedAlarm.id
          ) {
            await handleRefresh();
          }
        }
      });

      return () => unsubscribe();
    }
  }, [selectedAlarm, userData?.agency]);

  return (
    <div className="home">
      <div className="homeContainer">
        <Navbar onToggleMute={handleToggleMute} />

        <div className="agencyName">{userData?.agency}</div>

        <div className="content">
          <div className="column callInfo">
            <h2>Call Info</h2>
            <div className="lastUpdatedText">{lastUpdatedText}</div>
            {selectedAlarm && (
              <div>
                <div className="callTypeBox">
                  <img
                    src={getCallCardImage(selectedAlarm.callcard)}
                    alt={selectedAlarm.callcard}
                    className="callTypeIcon"
                    width="35"
                    height="35"
                  />
                  {selectedAlarm.CallType}
                </div>
                <div className="callInfoBox">{selectedAlarm.DispatchCode}</div>
                <h3>Dispatch Time</h3>
                <div className="callInfoBox">{selectedAlarm.Eventtime}</div>
                <h3>Incident Location</h3>
                <div className="callInfoBox">
                {selectedAlarm.LocationComments}
                <br />
                  {selectedAlarm.Address}
                  <br />
                  {selectedAlarm.Town}
                  <br />
                  {selectedAlarm.CrossStreet}
                </div>
                <h3>Dispatch Comments</h3>
                <div className="callInfoBox">{selectedAlarm.FRESComments}</div>
                <h3>Supplemental Comments/PROQA</h3>
                <div className="callInfoBox">{selectedAlarm.EventComments}</div>
                <h3>Caller Information</h3>
                <div className="callInfoBox">{selectedAlarm.CallBack}</div>
              </div>
            )}
          </div>
          <div className="column map">
            <div className="dispatch-tools">
              <h2>Dispatch Tools</h2>
              <div className="button-group">
                <button
                  className="dispatch-button"
                  onClick={() => handleDispatchResponse("Rescue On The Road")}
                >
                  <FaAmbulance className="dispatch-icon" /> Rescue Responding
                </button>
                <button
                  className="dispatch-button"
                  onClick={() => handleDispatchResponse("Fire On The Road")}
                >
                  <FaFireAlt className="dispatch-icon" /> Fire Responding
                </button>
                <button className="dispatch-button" onClick={handleRefresh}>
                  <FaSyncAlt className="dispatch-icon" /> Refresh
                </button>
                {selectedAlarm && (
                  <button
                    className="dispatch-button"
                    onClick={() => setIsCloseAlarmModalOpen(true)}
                  >
                    <FaTimes className="dispatch-icon" /> Close Alarm
                  </button>
                )}
              </div>
            </div>
            <GoogleMapComponent
              location={mapLocation}
              hydrants={hydrantLocations}
              vehicles={vehicleUnits}
              callerLocation={callerLocation}
              alarms={alarmData}
              onAlarmSelect={setSelectedAlarm}
            />
          </div>
          <div className="column callQueue">
            <div className="callQueueTop">
              <h2>Call Queue</h2>
              <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 460 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Call Type</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Dispatch Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {alarmData.map((alarm, index) => (
                      <TableRow
                        key={index}
                        onClick={() => handleRowClick(alarm)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          {alarm.callcard === "EMS" ? (
                            <img
                              src={EMS}
                              alt="EMS"
                              className="image"
                              width="35"
                              height="35"
                            />
                          ) : alarm.callcard === "FIRE" ? (
                            <img
                              src={FIRE}
                              alt="Fire"
                              className="image"
                              width="35"
                              height="35"
                            />
                          ) : alarm.callcard === "**HIGH PRIORITY**" ? (
                            <img
                              src={HP}
                              alt="High Priority"
                              className="image"
                              width="35"
                              height="35"
                            />
                          ) : (
                            alarm.callcard
                          )}
                        </TableCell>
                        <TableCell>{alarm.CallType}</TableCell>
                        <TableCell>{alarm.Address}</TableCell>
                        <TableCell>{alarm.Eventtime}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="callQueueBottom">
              <h2>Call Ins</h2>
              <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 460 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Callin User</TableCell>
                      <TableCell>Qualifications</TableCell>
                      <TableCell>Response</TableCell>
                      <TableCell>Call In Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedAlarm &&
                      callins &&
                      callins.map((callin, index) => (
                        <TableRow key={index}>
                          <TableCell>{callin.name}</TableCell>
                          <TableCell>
                            {callin.qualifications.join(", ")}
                          </TableCell>
                          <TableCell>{callin.response}</TableCell>
                          <TableCell>
                            {new Date(
                              callin.timestamp.seconds * 1000
                            ).toLocaleTimeString()}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <div className="footer">Copyright 2025 Ladder Suite LLC      Version 0.1.7</div>
      </div>
      <Dialog
        open={isCloseAlarmModalOpen}
        onClose={() => setIsCloseAlarmModalOpen(false)}
      >
        <DialogTitle>Close Alarm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to close out this Alarm for all users. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsCloseAlarmModalOpen(false)}>No</Button>
          <Button onClick={handleCloseAlarm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Home;