import React, { useState, useEffect, useCallback, useRef } from "react";
import { GoogleMap, LoadScript, Marker, TrafficLayer, InfoWindow, OverlayView } from "@react-google-maps/api";
import hydrantIcon from "../../Assets/hydrant.png"; 
import engineIcon from "../../Assets/fire-truck.png"; 
import chiefIcon from "../../Assets/default.png"; 
import ambulanceIcon from "../../Assets/ambulance.png"; 
import responderIcon from "../../Assets/default.png"; 
import firePin from "../../Assets/firepin.png"; 
import emsPin from "../../Assets/emspin.png"; 
import markerImage from "../../Assets/call.png";
import "./GoogleMapComponent.scss";

const containerStyle = {
  width: '100%',
  height: '100%'
};

const defaultCenter = {
  lat: 40.8228121,
  lng: -73.1496657
};

const GoogleMapComponent = ({ location, hydrants, vehicles, callerLocation, alarms, onAlarmSelect }) => {
  const [mapRegion, setMapRegion] = useState({
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
  });
  const [hydrantLayerVisible, setHydrantLayerVisible] = useState(true); // State to manage hydrant layer visibility
  const [filteredHydrants, setFilteredHydrants] = useState([]);
  const [selectedHydrant, setSelectedHydrant] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null); 
  const [userHasPanned, setUserHasPanned] = useState(false);
  const [currentCenter, setCurrentCenter] = useState(location ? { lat: location.lat, lng: location.lng } : defaultCenter);
  const mapRef = useRef(null);

  useEffect(() => {
    if (!userHasPanned && location) {
      setCurrentCenter({ lat: location.lat, lng: location.lng });
    }
  }, [location, userHasPanned]);

  const mapOptions = {
    mapTypeId: 'hybrid', 
    disableDefaultUI: true, 
    zoomControl: true, 
    scaleControl: true, 
    streetViewControl: true 
  };

  const isHydrantInRegion = useCallback((hydrant, region) => {
    if (!window.google) return false;
    const { Shape_X, Shape_Y } = hydrant;
    const { lat, lng, latitudeDelta, longitudeDelta } = region;

    return (
      Shape_X >= lng - longitudeDelta / 2 &&
      Shape_X <= lng + longitudeDelta / 2 &&
      Shape_Y >= lat - latitudeDelta / 2 &&
      Shape_Y <= lat + latitudeDelta / 2
    );
  }, []);

  useEffect(() => {
    if (!window.google) return;
    const filterHydrants = () => {
      const filtered = hydrants
        .filter((hydrant) => isHydrantInRegion(hydrant, mapRegion))
        .slice(0, 50); // Limit to the first 50 hydrants
      setFilteredHydrants(filtered);
    };

    filterHydrants();
  }, [mapRegion, hydrants, isHydrantInRegion]);

  const handleRegionChangeComplete = useCallback(() => {
    if (mapRef.current && window.google) {
      const map = mapRef.current;
      const bounds = map.getBounds();
      if (bounds) {
        const center = map.getCenter();
        setMapRegion({
          lat: center.lat(),
          lng: center.lng(),
          latitudeDelta: bounds.toSpan().lat(),
          longitudeDelta: bounds.toSpan().lng(),
        });
      }
    }
  }, []);

  const handleLoad = useCallback((map) => {
    mapRef.current = map;
    handleRegionChangeComplete();
  }, [handleRegionChangeComplete]);

  const handleUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  const handleDragStart = () => {
    setUserHasPanned(true);
  };

  const handleDragEnd = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      setCurrentCenter({
        lat: center.lat(),
        lng: center.lng()
      });
    }
  };

  const getVehicleIcon = (vehicleType) => {
    switch ((vehicleType || "").toLowerCase()) {
      case "engine":
        return engineIcon;
      case "chief":
        return chiefIcon;
      case "ambulance":
        return ambulanceIcon;
      case "responder":
        return responderIcon;
      default:
        return responderIcon; 
    }
  };

  const getMarkerImage = (alarm) => {
    if (alarm?.callcard === 'FIRE') {
      return firePin;
    } else if (alarm?.callcard === 'EMS') {
      return emsPin;
    } else {
      return markerImage;
    }
  };

  const openFullScreenMap = () => {
    const url = `/fullscreen-map`; // Simply navigate to the full-screen map route
  
    console.log('Attempting to open URL:', url); // Log the URL for debugging
  
    try {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer'); // Open in a new tab or window
      if (newWindow) {
        newWindow.opener = null; // Security: Prevent the new window from accessing the opener
        console.log('Pop-up opened successfully');
      } else {
        console.error('Pop-up blocked or failed to open');
      }
    } catch (error) {
      console.error('Error opening pop-up:', error); // Log any errors
    }
  };
  

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <LoadScript 
        googleMapsApiKey="AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM"
        onLoad={() => console.log('Script loaded successfully')}
        onError={(error) => console.error('Script loading error: ', error)}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={currentCenter}
          zoom={18}
          options={mapOptions}
          onLoad={handleLoad}
          onUnmount={handleUnmount}
          onIdle={handleRegionChangeComplete}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          {alarms && alarms.map((alarm, index) => (
            <Marker
              key={index}
              position={{ lat: alarm.location.lat, lng: alarm.location.lng }}
              icon={{
                url: getMarkerImage(alarm),
                scaledSize: new window.google.maps.Size(40, 40),
              }}
              onClick={() => {
                onAlarmSelect(alarm);
                if (alarm?.location?.lat && alarm?.location?.lng) {
                  if (mapRef.current) {
                    mapRef.current.panTo({ lat: alarm.location.lat, lng: alarm.location.lng });
                    mapRef.current.setZoom(19);
                  }
                }
              }}
            />
          ))}
          {hydrantLayerVisible && filteredHydrants.map((hydrant, index) => (
            <Marker
              key={index}
              position={{ lat: parseFloat(hydrant.Shape_Y), lng: parseFloat(hydrant.Shape_X) }}
              icon={{
                url: hydrantIcon,
                scaledSize: new window.google.maps.Size(30, 30), 
              }}
              onClick={() => setSelectedHydrant(hydrant)}
            />
          ))}
          {selectedHydrant && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedHydrant.Shape_Y),
                lng: parseFloat(selectedHydrant.Shape_X)
              }}
              onCloseClick={() => setSelectedHydrant(null)}
            >
              <div style={{ backgroundColor: '#212c40', color: 'white', padding: '10px', borderRadius: '5px' }}>
                <h4 style={{ margin: 0, color: 'white' }}>Hydrant Info</h4>
                <p style={{ margin: 0 }}><strong>ID:</strong> {selectedHydrant.HYDRANT_ID}</p>
                <p style={{ margin: 0 }}><strong>Number:</strong> {selectedHydrant.HYDRANT_NU}</p>
                <p style={{ margin: 0 }}><strong>Coordinates:</strong> {selectedHydrant.Shape_Y}, {selectedHydrant.Shape_X}</p>
              </div>
            </InfoWindow>
          )}
          {vehicles.map((vehicle, index) => (
            <Marker
              key={`vehicle-${index}`}
              position={{ lat: vehicle.latitude, lng: vehicle.longitude }}
              icon={{
                url: getVehicleIcon(vehicle.vehicleType),
                scaledSize: new window.google.maps.Size(32, 32)
              }}
              onClick={() => setSelectedVehicle(vehicle)}
            />
          ))}
          {callerLocation && (
            <OverlayView
              position={{ lat: callerLocation.latitude, lng: callerLocation.longitude }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div style={{
                position: 'relative',
                width: '50px',
                height: '50px'
              }}>
                <div style={{
                  position: 'absolute',
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(0, 255, 0, 0.4)',
                  transform: 'translate(-50%, -50%)',
                  animation: 'pulse 2s infinite'
                }} />
                <div style={{
                  position: 'absolute',
                  width: '12px',
                  height: '12px',
                  backgroundColor: '#00ff00',
                  border: '2px solid white',
                  borderRadius: '50%',
                  transform: 'translate(-50%, -50%)'
                }} />
                <div style={{
                  position: 'absolute',
                  top: '20px',
                  left: '15%',
                  transform: 'translateX(-50%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: 'white',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold'
                }}>
                  Caller Location
                </div>
                <style>
                  {`
                    @keyframes pulse {
                      0% {
                        transform: translate(-50%, -50%) scale(0.5);
                        opacity: 0.8;
                      }
                      70% {
                        transform: translate(-50%, -50%) scale(2);
                        opacity: 0;
                      }
                      100% {
                        transform: translate(-50%, -50%) scale(0.5);
                        opacity: 0;
                      }
                    }
                  `}
                </style>
              </div>
            </OverlayView>
          )}
          {selectedVehicle && (
            <InfoWindow
              position={{ lat: selectedVehicle.latitude, lng: selectedVehicle.longitude }}
              onCloseClick={() => setSelectedVehicle(null)}
            >
              <div style={{ backgroundColor: '#212c40', color: 'white', padding: '10px', borderRadius: '5px' }}>
                <h4 style={{ margin: 0, color: 'white' }}>{selectedVehicle.responderName}</h4>
                <p style={{ margin: 0 }}><strong>Type:</strong> {selectedVehicle.vehicleType}</p>
                <p style={{ margin: 0 }}><strong>Last Updated:</strong> {new Date(selectedVehicle.timestamp).toLocaleString()}</p>
              </div>
            </InfoWindow>
          )}
          <TrafficLayer /> 
        </GoogleMap>
      </LoadScript>

      {/* Toggle Button for Hydrant Layer */}
      <div style={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
        <button 
          onClick={() => setHydrantLayerVisible(!hydrantLayerVisible)}
          style={{
            padding: '10px 20px',
            backgroundColor: hydrantLayerVisible ? '#d9534f' : '#5bc0de',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          {hydrantLayerVisible ? 'Hide Hydrants' : 'Show Hydrants'}
        </button>
      </div>

      {/* Button to open the map in a new screen */}
      <div style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
        <button 
          onClick={openFullScreenMap}
          style={{
            padding: '10px 20px',
            backgroundColor: '#5bc0de',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Fullscreen
        </button>
      </div>
    </div>
  );
};

export default GoogleMapComponent;