import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { GoogleMap, LoadScript, Marker, TrafficLayer, InfoWindow, OverlayView } from "@react-google-maps/api";
import hydrantIcon from "../../Assets/hydrant.png"; 
import engineIcon from "../../Assets/fire-truck.png"; 
import chiefIcon from "../../Assets/default.png"; 
import ambulanceIcon from "../../Assets/ambulance.png"; 
import responderIcon from "../../Assets/default.png";  
import firePin from "../../Assets/firepin.png"; 
import emsPin from "../../Assets/emspin.png"; 
import markerImage from "../../Assets/call.png";
import hydrantdata from "../../Assets/suffolkhydrants.json"; 
import { getDatabase, ref, onValue, get, child } from "firebase/database";
import { getFirestore, collection, query, where, onSnapshot, Timestamp } from 'firebase/firestore'; 
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import axios from 'axios';
import moment from 'moment';
import { useLocation, useSearchParams } from 'react-router-dom';
import "./GoogleMapComponent.scss";

const containerStyle = {
  width: '100vw',
  height: '100vh',
};

const defaultCenter = {
  lat: 40.8228121,
  lng: -73.1496657
};

const darkModeStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
];

const FullScreenMap = () => {
    const { userData } = useContext(AuthContext);
    const [searchParams] = useSearchParams();
    const [mapRegion, setMapRegion] = useState({
      lat: defaultCenter.lat,
      lng: defaultCenter.lng,
      latitudeDelta: 0.01,
      longitudeDelta: 0.01,
    });
    const [hydrantLayerVisible, setHydrantLayerVisible] = useState(true);
    const [trafficLayerVisible, setTrafficLayerVisible] = useState(true); // State for traffic layer visibility
    const [filteredHydrants, setFilteredHydrants] = useState([]);
    const [selectedHydrant, setSelectedHydrant] = useState(null);
    const [selectedVehicle, setSelectedVehicle] = useState(null); 
    const [hydrants, setHydrants] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [alarms, setAlarms] = useState([]);
    const [selectedAlarm, setSelectedAlarm] = useState(null);
    const [darkMode, setDarkMode] = useState(false); // State for dark mode
    const [callerLocation, setCallerLocation] = useState(null);
    const mapRef = useRef(null);
  
    useEffect(() => {
      setHydrants(hydrantdata);
  
      if (userData?.agency) {
        const unitsRef = ref(getDatabase(), `/UnitLocation/${userData.agency}`);
        const unsubscribe = onValue(unitsRef, (snapshot) => {
          const unitsData = snapshot.val();
          if (unitsData) {
            const unitArray = Object.keys(unitsData).map(key => ({
              responderName: key,
              ...unitsData[key],
            }));
            setVehicles(unitArray);
          }
        });
  
        return () => unsubscribe();
      }
    }, [userData]);
  
    const fetchAlarms = useCallback(() => {
      if (userData?.agency) {
        const currentTime = new Date();
        const pastTime = new Date(currentTime.getTime() - 1.5 * 60 * 60 * 1000);
        console.log("Fetching alarms from:", pastTime);
    
        const q = query(
          collection(getFirestore(), `${userData.agency} Alarms`),
          where("TimeStamp", ">=", Timestamp.fromDate(pastTime))
        );
    
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          console.log("Query snapshot size:", querySnapshot.size);
          const alarms = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const data = doc.data();
              if (!data.RunNumber) return null; // Filter out documents that don't have "RunNumber"
              
              let location;
              if (data.Latitude && data.Longitude) {
                location = {
                  lat: parseFloat(data.Latitude),
                  lng: parseFloat(data.Longitude)
                };
              } else {
                location = await geocodeAddress(data.Address, data.Town);
              }

              // Use Eventtime if TimeStamp is null
              const timeStamp = data.TimeStamp
                ? data.TimeStamp.toDate()
                : moment(
                    data.Eventtime.split("Dispatch Time: ")[1],
                    "MM-DD-YYYY HH:mm:ss"
                  ).toDate();
    
              return { ...data, location, id: doc.id, timeStamp };
            })
          );
    
          const nonNullAlarms = alarms.filter((alarm) => alarm !== null);
          nonNullAlarms.sort((a, b) => b.timeStamp - a.timeStamp);
    
          setAlarms(nonNullAlarms);
          console.log("Fetched alarms with locations:", nonNullAlarms);
        });
    
        return () => unsubscribe();
      }
    }, [userData]);
  
  
    const geocodeAddress = async (address, town) => {
      const apiKey = "AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM";
      const fullAddress = `${address}, ${town}`;
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: fullAddress,
            key: apiKey,
          },
        }
      );
    
      if (response.data.status === "OK") {
        const location = response.data.results[0].geometry.location;
        return location;
      } else {
        console.error("Geocoding error:", response.data.status);
        return null;
      }
    };
  
    useEffect(() => {
      fetchAlarms();
    }, [fetchAlarms]);
  
    const extractCallerCoordinates = useCallback((eventComments) => {
      console.log('Extracting caller coordinates from Event Comments');
      
      // Define a regular expression pattern to match coordinates
      const regex = /-?\d+\.\d+\s+-?\d+\.\d+/;
      
      if (!eventComments) {
        console.log('No Event Comments available.');
        return;
      }

      // Find matches in the EventComments text
      const matches = eventComments.match(regex);
      
      if (matches && matches.length > 0) {
        // The first match contains the coordinates
        const coordinates = matches[0];
        console.log('Extracted Coordinates:', coordinates);
      
        // Parse the coordinates
        const [longitudeStr, latitudeStr] = coordinates.split(' ');
        const latitude = parseFloat(latitudeStr);
        const longitude = parseFloat(longitudeStr);
        
        console.log('Parsed Latitude:', latitude);
        console.log('Parsed Longitude:', longitude);
        console.log('Setting callerLocation state...');
      
        if (!isNaN(latitude) && !isNaN(longitude)) {
          // Save the coordinates in state
          setCallerLocation({ latitude, longitude });
        } else {
          console.log('Invalid coordinates extracted.');
        }
      } else {
        console.log('No coordinates found in Event Comments.');
      }
    }, []);

    const handleAlarmClick = useCallback((alarm) => {
      setSelectedAlarm(alarm);
      if (alarm?.location?.lat && alarm?.location?.lng) {
        setMapRegion({
          lat: alarm.location.lat,
          lng: alarm.location.lng,
          latitudeDelta: 0.01,
          longitudeDelta: 0.01,
        });
      }
      // Extract caller coordinates when an alarm is selected
      if (alarm?.EventComments) {
        extractCallerCoordinates(alarm.EventComments);
      }
    }, [extractCallerCoordinates]);
  
    const mapCenter = defaultCenter;
  
    const mapOptions = {
      mapTypeId: darkMode ? 'roadmap' : 'hybrid', // Change map type based on dark mode
      disableDefaultUI: true, 
      zoomControl: true, 
      scaleControl: true, 
      streetViewControl: true,
      styles: darkMode ? darkModeStyle : [], // Apply dark mode styles when darkMode is true
    };
  
    const isHydrantInRegion = useCallback((hydrant, region) => {
      if (!window.google) return false;
      const { Shape_X, Shape_Y } = hydrant;
      const { lat, lng, latitudeDelta, longitudeDelta } = region;
  
      return (
        Shape_X >= lng - longitudeDelta / 2 &&
        Shape_X <= lng + longitudeDelta / 2 &&
        Shape_Y >= lat - latitudeDelta / 2 &&
        Shape_Y <= lat + latitudeDelta / 2
      );
    }, []);
  
    useEffect(() => {
      if (!window.google || hydrants.length === 0) return;
  
      const filterHydrants = () => {
        const filtered = hydrants
          .filter((hydrant) => isHydrantInRegion(hydrant, mapRegion))
          .slice(0, 50);
        setFilteredHydrants(filtered);
      };
  
      filterHydrants();
    }, [mapRegion, hydrants, isHydrantInRegion]);
  
    useEffect(() => {
      const alarmId = searchParams.get('alarmId');
      if (alarmId && alarms.length > 0) {
        const alarm = alarms.find(a => a.id === alarmId);
        if (alarm) {
          setSelectedAlarm(alarm);
          if (alarm?.location?.lat && alarm?.location?.lng) {
            // Center the map on the alarm location
            if (mapRef.current) {
              mapRef.current.panTo({ lat: alarm.location.lat, lng: alarm.location.lng });
              mapRef.current.setZoom(17);
            }
            setMapRegion({
              lat: alarm.location.lat,
              lng: alarm.location.lng,
              latitudeDelta: 0.01,
              longitudeDelta: 0.01,
            });
          }
          if (alarm?.EventComments) {
            extractCallerCoordinates(alarm.EventComments);
          }
        }
      }
    }, [searchParams, alarms, extractCallerCoordinates]);

    useEffect(() => {
      if (userData?.agency) {
        const selectedAlarmRef = ref(getDatabase(), `/SelectedAlarm/${userData.agency}`);
        const unsubscribe = onValue(selectedAlarmRef, (snapshot) => {
          const data = snapshot.val();
          if (data?.alarmId && alarms.length > 0) {
            const alarm = alarms.find(a => a.id === data.alarmId);
            if (alarm) {
              setSelectedAlarm(alarm);
              if (alarm?.location?.lat && alarm?.location?.lng) {
                // Center the map on the alarm location
                if (mapRef.current) {
                  mapRef.current.panTo({ lat: alarm.location.lat, lng: alarm.location.lng });
                  mapRef.current.setZoom(17);
                }
                setMapRegion({
                  lat: alarm.location.lat,
                  lng: alarm.location.lng,
                  latitudeDelta: 0.01,
                  longitudeDelta: 0.01,
                });
              }
              if (alarm?.EventComments) {
                extractCallerCoordinates(alarm.EventComments);
              }
            }
          }
        });

        return () => unsubscribe();
      }
    }, [userData, alarms, extractCallerCoordinates]);
  
    const handleRegionChangeComplete = useCallback(() => {
      if (mapRef.current && window.google) {
        const map = mapRef.current;
        const bounds = map.getBounds();
        if (bounds) {
          const center = map.getCenter();
          setMapRegion({
            lat: center.lat(),
            lng: center.lng(),
            latitudeDelta: bounds.toSpan().lat(),
            longitudeDelta: bounds.toSpan().lng(),
          });
        }
      }
    }, []);
  
    const handleLoad = useCallback((map) => {
      mapRef.current = map;
      handleRegionChangeComplete();
    }, [handleRegionChangeComplete]);
  
    const handleUnmount = useCallback(() => {
      mapRef.current = null;
    }, []);
  
    const getVehicleIcon = (vehicleType) => {
      switch ((vehicleType || "").toLowerCase()) {
        case "engine":
          return engineIcon;
        case "chief":
          return chiefIcon;
        case "ambulance":
          return ambulanceIcon;
        case "responder":
          return responderIcon;
        default:
          return responderIcon; 
      }
    };
  
    const getMarkerImage = (alarm) => {
      if (alarm.callcard === 'FIRE') {
        return firePin;
      } else if (alarm.callcard === 'EMS') {
        return emsPin;
      } else {
        return markerImage; // Use the default marker for other cases
      }
    };
  
    const toggleDarkMode = () => {
      setDarkMode((prevMode) => !prevMode);
    };
  
    const toggleHydrantLayer = () => {
      setHydrantLayerVisible((prevVisibility) => !prevVisibility);
    };
  
    const toggleTrafficLayer = () => {
      setTrafficLayerVisible((prevVisibility) => !prevVisibility);
    };
  
    return (
      <div>
        <button 
          onClick={toggleDarkMode} 
          style={{ 
            position: 'absolute', 
            top: 10, 
            left: 10, 
            zIndex: 1000, 
            padding: '10px', 
            backgroundColor: darkMode ? '#ffffff' : '#333333', 
            color: darkMode ? '#333333' : '#ffffff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Toggle Dark Mode
        </button>
        <button 
          onClick={toggleHydrantLayer} 
          style={{ 
            position: 'absolute', 
            top: 60, 
            left: 10, 
            zIndex: 1000, 
            padding: '10px', 
            backgroundColor: hydrantLayerVisible ? '#ffffff' : '#333333', 
            color: hydrantLayerVisible ? '#333333' : '#ffffff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Toggle Hydrants
        </button>
        <button 
          onClick={toggleTrafficLayer} 
          style={{ 
            position: 'absolute', 
            top: 110, 
            left: 10, 
            zIndex: 1000, 
            padding: '10px', 
            backgroundColor: trafficLayerVisible ? '#ffffff' : '#333333', 
            color: trafficLayerVisible ? '#333333' : '#ffffff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Toggle Traffic
        </button>
        <LoadScript 
          googleMapsApiKey="AIzaSyDEYcdHgZwlpq3QxIRqtT8G3NmodCSwhiM"
          onLoad={() => console.log('Script loaded successfully')}
          onError={(error) => console.error('Script loading error: ', error)}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={17}
            options={mapOptions}
            onLoad={handleLoad}
            onUnmount={handleUnmount}
            onIdle={handleRegionChangeComplete}
          >
            {mapCenter && <Marker position={mapCenter} />}
            {hydrantLayerVisible && filteredHydrants.map((hydrant, index) => (
              <Marker
                key={index}
                position={{ lat: parseFloat(hydrant.Shape_Y), lng: parseFloat(hydrant.Shape_X) }}
                icon={{
                  url: hydrantIcon,
                  scaledSize: new window.google.maps.Size(30, 30), 
                }}
                onClick={() => setSelectedHydrant(hydrant)}
              />
            ))}
            {alarms.map((alarm, index) => (
              <Marker
                key={index}
                position={{ lat: alarm.location.lat, lng: alarm.location.lng }}
                icon={{
                  url: getMarkerImage(alarm),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                onClick={() => {
                  setSelectedAlarm(alarm);
                  if (alarm?.location?.lat && alarm?.location?.lng) {
                    // Center the map on the alarm location
                    if (mapRef.current) {
                      mapRef.current.panTo({ lat: alarm.location.lat, lng: alarm.location.lng });
                      mapRef.current.setZoom(17); // Set an appropriate zoom level
                    }
                    setMapRegion({
                      lat: alarm.location.lat,
                      lng: alarm.location.lng,
                      latitudeDelta: 0.01,
                      longitudeDelta: 0.01,
                    });
                  }
                  if (alarm?.EventComments) {
                    extractCallerCoordinates(alarm.EventComments);
                  }
                }}
              />
            ))}
            {selectedHydrant && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedHydrant.Shape_Y),
                  lng: parseFloat(selectedHydrant.Shape_X)
                }}
                onCloseClick={() => setSelectedHydrant(null)}
              >
                <div style={{ backgroundColor: '#212c40', color: 'white', padding: '10px', borderRadius: '5px' }}>
                  <h4 style={{ margin: 0, color: 'white' }}>Hydrant Info</h4>
                  <p style={{ margin: 0 }}><strong>ID:</strong> {selectedHydrant.HYDRANT_ID}</p>
                  <p style={{ margin: 0 }}><strong>Number:</strong> {selectedHydrant.HYDRANT_NU}</p>
                  <p style={{ margin: 0 }}><strong>Coordinates:</strong> {selectedHydrant.Shape_Y}, {selectedHydrant.Shape_X}</p>
                </div>
              </InfoWindow>
            )}
            {selectedAlarm && (
              <InfoWindow
                position={{
                  lat: selectedAlarm.location.lat,
                  lng: selectedAlarm.location.lng,
                }}
                onCloseClick={() => setSelectedAlarm(null)}
              >
                <div style={{ backgroundColor: '#212c40', color: 'white', padding: '10px', borderRadius: '5px' }}>
                  <h4 style={{ margin: 0, color: 'white' }}>{selectedAlarm.CallType}</h4>
                  <p style={{ margin: 0 }}><strong>Address:</strong> {selectedAlarm.Address}</p>
                  <p style={{ margin: 0 }}><strong>Time:</strong> {new Date(selectedAlarm.TimeStamp.toDate()).toLocaleString()}</p>
                </div>
              </InfoWindow>
            )}
            {vehicles.map((vehicle, index) => (
              <Marker
                key={index}
                position={{ lat: vehicle.latitude, lng: vehicle.longitude }}
                icon={{
                  url: getVehicleIcon(vehicle.vehicletype),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                onClick={() => setSelectedVehicle(vehicle)}
              />
            ))} 
            {selectedVehicle && (
              <InfoWindow
                position={{ lat: selectedVehicle.latitude, lng: selectedVehicle.longitude }}
                onCloseClick={() => setSelectedVehicle(null)}
              >
                <div style={{ backgroundColor: '#212c40', color: 'white', padding: '10px', borderRadius: '5px' }}>
                  <h4 style={{ margin: 0, color: 'white' }}>{selectedVehicle.responderName}</h4>
                  <p style={{ margin: 0 }}><strong>Type:</strong> {selectedVehicle.vehicletype}</p>
                  <p style={{ margin: 0 }}><strong>Last Updated:</strong> {new Date(selectedVehicle.timestamp).toLocaleString()}</p>
                </div>
              </InfoWindow>
            )}
            {callerLocation && (
              <OverlayView
                position={{ lat: callerLocation.latitude, lng: callerLocation.longitude }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div style={{ position: 'relative' }}>
                  <div className="pulse-ring"></div>
                  <div className="center-marker"></div>
                  <div className="crosshair-horizontal"></div>
                  <div className="crosshair-vertical"></div>
                  <div className="caller-label">Caller Location</div>
                </div>
              </OverlayView>
            )}
            {trafficLayerVisible && <TrafficLayer />}
          </GoogleMap>
        </LoadScript>
      </div>
    );
  };
  
  export default FullScreenMap;
